<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                
                                <el-row>
                                    <el-col :span="12" align="left">
                                        <span v-loadimage="store_address.website_logo?store_address.website_logo:''"  ></span>
                                    </el-col>
                                    <el-col :span="12" align="right">
                                            <b >
                                    <label class="mt-10">
                                      {{store_address.address?store_address.address +', ':''}}
                                      {{store_address.city_details? store_address.city_details.city_name+', ': ''}}
                                      {{store_address.state_details? store_address.state_details.state_name:''}} {{store_address.post_code ? ' - '+store_address.post_code : ''}}</label><br>
                                    <label>Phone: {{store_address.phone?store_address.phone:''}}</label><br>
                                    <label>Fax: {{store_address.fax?store_address.fax:''}}</label><br>
                                    <label>Email: {{store_address.email_address?store_address.email_address:''}}</label>
                                    </b>
                                    <br>
                                    </el-col>
                                  </el-row>
                                   <hr class="invoice-line">
                                  <el-row>
                                     
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>CANCEL PURCHASE ORDER</b></h2>
                                      </el-col>
                                                                           
                                  </el-row>
                                   <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PURCHASE ORDER #</b></span><span><b> {{view.po_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>SUPPLIER ID : </b></span><span><b> {{view.supplier.supplier_Id}}</b></span>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name">{{view.supplier? view.supplier.company_name:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && (view.supplier.unit_no || view.supplier.street)">{{view.supplier.street ? view.supplier.street:'' }}{{view.supplier.unit_no?', Unit No : '+ view.supplier.unit_no:'' }} </div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.city">{{view.supplier.city? view.supplier.city.city_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.state">{{view.supplier.state? view.supplier.state.state_name :''}}{{view.supplier.postal_code? ' - '+view.supplier.postal_code:''}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                        <div class="billing-adr" style="padding-left:10px;" v-if="view.delivery_type == 'Delivery'">SHIP TO :</div>
                                         <div class="billing-adr" style="padding-left:10px;" v-else>PickUp :</div>
                                         <div style="padding: 3px;" v-if="view.store_details && view.store_details.website_name && view.delivery_type == 'Delivery'">{{view.store_details?view.store_details.website_name:''}}</div>
                                         <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name && view.delivery_type == 'PickUp'">{{view.supplier.company_name}}</div>
                                         <div style="padding: 3px;" v-if="view.address || view.unit_no">{{view.address}}{{view.unit_no?', Unit No : '+ view.unit_no:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.city && view.city.city_name ">{{view.city.city_name ? view.city.city_name: '' }}</div>
                                          <div style="padding: 3px;" v-if="view.state && view.state.state_name ">{{view.state.state_name}}{{view.post_code ? ' - '+view.post_code : ''}}</div>
                                      </el-col>
                                    </el-row>
                                     <div class="row">
                                      <div class="col-xl-12 ">
                                       
                                          <el-table :data="form.products" stripe border fit @selection-change="handleSelectionChange">                                                         
                                  <el-table-column
                                    type="selection"
                                    width="55">                                    
                                  </el-table-column>
                                  <el-table-column label="Supplier SKU" width="150">
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].supplier_sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>                                
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.name}} - <b>{{form.products[scope.$index].product_item.sku}}</b></span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Quantity" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Cancel Quantity" width="150" align="center">
                                     <template slot-scope="scope">
                                       <el-input-number class="return_product" 
                                       v-model="form.products[scope.$index].cancel_quantity" auto-complete="nope"  :controls="false" :min="0"
                                       :max="form.products[scope.$index].back_quantity"
                                        @change="getReturnPrice(scope.$index)" ></el-input-number>                                          
                                      </template>                            
                                  </el-table-column>  
                                    <el-table-column label="Price" width="100" align="center">
                                     <template slot-scope="scope">
                                       <span>${{form.products[scope.$index].unit_price}}</span>
                                      </template>                            
                                  </el-table-column> 
                                   <el-table-column label="Total" width="100" align="center">
                                     <template slot-scope="scope">
                                       <span>${{form.products[scope.$index].cancel_total? parseInt(form.products[scope.$index].cancel_total).toFixed(2):'0.00'}}</span>
                                      </template>                            
                                  </el-table-column>                       
                                </el-table>  
                                <span v-if="this.quantityError" class="red mt-5">{{this.quantityError}}</span> 
                                <el-form>
                                <el-row class="pricing-section mt-7 ">
                                          <el-col :span="16">
                                            <el-col :span="20">                                                            
                                              <el-form-item label="Cancel Reason">
                                                <el-input type="textarea" @input.native="capitalize" :rows="4" v-model="form.cancel_reason"></el-input>
                                              </el-form-item> 
                                              <span class="form-err" v-if="!form.cancel_reason && !form.errors.has('cancel_reason')">The cancel reason field is required</span> 
                                              <span class="form-err" v-if="form.errors.has('cancel_reason')" >{{ form.errors.get("cancel_reason") }}</span>                                                         
                                            </el-col>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form :label-position="labelPosition" label-width="150px" >
                                                <el-form-item label="Sub Total" prop="discount">
                                              <el-input v-model="form.sub_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                            </el-form-item>                                    
                                            <el-form-item  :label="'Tax HST(' + view.tax_per + '%)'" prop="tax_total">
                                              <el-input v-model="form.tax_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                            </el-form-item>
                                               
                                                <el-form-item label="Total" prop="total">
                                                    <el-input v-model="form.cancel_amount" auto-complete="off" disabled class="sub-total return_product"></el-input>
                                                </el-form-item>
                                                </el-form>
                                            </el-col>
                                        </el-row>
                                </el-form>
                                  <el-row style="margin-top:25px;">
                                      <el-form >
                                     
                                      <el-col :span="5" style="margin-top:20px">
                                      <el-form-item >
                                            <el-button type="primary" @click="cancelPurchaseOrder">Cancel Order</el-button>
                                      </el-form-item>
                                      </el-col>
                                      </el-form>
                                         </el-row>    
                                      </div>
                                     </div>

                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getReceivePurchaseorder, downloadPdf} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail'
import Form from '@/core/services/api.form.services'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      store_address : [], 
      labelPosition: "right", 
      submit_btn_text:'Received',    
      view_type: 'purchaseorder',
      api_url:'cancel/purchase/order',
        form : new Form({
        receive_verified_by:null,
        po_id:null, 
        cancel_reason:null,
        sub_total:0.00,
        tax_total:0.00, 
        cancel_amount:0,     
          products: [
           {
             id :null,
             product_id: null,                       
             quantity: null,            
             cancel_quantity:null,
             back_quantity:null, 
             cancel_total:null,           
             product_item: [{
               sku: null,
               name:null
             }]            
           }
         ]

       }),
       quantityError:null,
       multipleSelection:[],
       cancel_qty_error: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Purchase Orders", route: "/purchase-order/list" }, 
      { title: "Cancel Purchase Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getReceivePurchaseorder(this.$route.params.poId).then(response => { 
       console.log(response.data.data);
        this.view = response.data.data 
        //this.form = new Form(response.data.data);  
        this.store_address = response.data.data.store_details 
        this.form.products = response.data.data.products;     
        this.view.tax_per = this.view.tax_per ? this.view.tax_per : 0;
        this.loading = false
      });
    },
     printWindow: function () {		
	     window.print();
    },
     handleSelectionChange(val) { 
          
       this.multipleSelection = val;  
       let i=0;
       this.form.products.map((check)=>{
          check.cancel_quantity = 0;  
          check.edited = false       
          val.map((selected)=>{
            if(check.id == selected.id){
                check.cancel_quantity =  check.back_quantity 
                check.edited = true                
              }
            })
          this.getReturnPrice(i++);
       })       
      
      },
       getReturnPrice(index){
      //let pid = this.form.products[index].product_id;       
      let unit_price = this.form.products[index].unit_price;     
      let quantity = this.form.products[index].cancel_quantity ? this.form.products[index].cancel_quantity : 0;    
      this.form.products[index].cancel_total = (quantity*unit_price);  
      this.calculateTotal();
    },
    
    calculateTotal() {			
        let sub_totals = 0;       
        this.form.products.map((product) => {
            sub_totals = sub_totals + product.cancel_total;           
        })

        let tax = 0;
        if(this.view.tax_per){
          tax = (sub_totals * this.view.tax_per / 100);
        }
        
        this.form.sub_total = '$' +parseFloat(sub_totals).toFixed(2);
        this.form.tax_total = '$' +parseFloat(tax).toFixed(2);

       
        this.cancelAmount = parseFloat(sub_totals + tax).toFixed(2);
        this.form.cancel_amount = '$' +parseFloat(sub_totals + tax).toFixed(2);

       
       
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     
    cancelPurchaseOrder(){
      this.form.po_id = this.$route.params.poId;
      this.quantityError = null;
      let cancelQty = 0;
        this.form.products.map((val)=>{
        if(val.cancel_quantity){         
           cancelQty = cancelQty + val.cancel_quantity;     
        }       
     })

     if(cancelQty <= 0){
        this.quantityError = 'Please Select Cancel Quantity';
     }
      if(!this.quantityError){
       this.form.post(this.api_url).then((response) => {
            if(response.status){
                this.$router.push({ path: '/purchase-order/list' });
                this.loading=false;
                this.$showResponse('success', response.message);
            }
          }).catch((error) => {
              this.loading = false;
                console.log(error)
              })
      }
    },
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.poId, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'purchaseorder.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.supplier;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    }    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;
      margin-top: 30px;
      margin-left: 10px;
    }
      .return_product .el-input__inner {
            /* padding-left: 0px;
            padding-right: 8px; */
            border: 1px solid #3699ff;
            text-align: end !important;
        }

    @media print {
       
        .print-section, .download-btn, .subheader, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    
}
 
</style>